import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from "recharts";

import Navigation from "../components/Navigation";
import React from "react";
import styled from "styled-components";

const CommunityView = styled.div`
  color: white;
  margin-left: 90px;
  display: flex;
  flex-direction: column;
  h1 {
    text-align: left;
  }
  .highlight {
    color: #a7d129;
    font-weight: bold;
  }
`;

const StyledRenderTooltip = styled.div`
  background-color: white;
  color: #a7d129;
  padding: 10px;
`;

const StyledChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

const Community = ({ history, ownedGames }) => {
  // console.log('ownedGames', ownedGames);
  const mostPlayedGamesByPerson = ownedGames.map((fren) => {
    const mostPlayedGame = fren.steamData.games.sort(
      (a, b) => b.playtime_forever - a.playtime_forever
    );
    const totalPlaytime = fren.steamData.games.reduce(
      (a, b) => a + b.playtime_forever,
      0
    );
    return {
      name: fren.name,
      mostPlayedGame: mostPlayedGame[0],
      totalPlaytime,
      allTimeFavouriteGamePlaytime: Math.round(
        mostPlayedGame[0].playtime_forever / 60
      ), // hours
      games: fren.steamData.games,
    };
  });

  // console.log('mostplayed games by person', mostPlayedGamesByPerson);

  const totalFavouriteGamesPlayedTimeInHours = mostPlayedGamesByPerson
    .filter((game) => game !== undefined)
    .reduce((acc, curr) => {
      return acc + curr.allTimeFavouriteGamePlaytime;
    }, 0);
  // console.log('totalFavouriteGamesPlayedTimeInHours', totalFavouriteGamesPlayedTimeInHours);
  const totalFavouriteGamesPlayedTimeInDays = (
    totalFavouriteGamesPlayedTimeInHours / 24
  ).toFixed(0);
  const totalFavouriteGamesPlayedTimeInYears = (
    totalFavouriteGamesPlayedTimeInDays / 365
  ).toFixed(2);

  const allGamesPlayTimeByHours = Math.round(
    mostPlayedGamesByPerson
      .filter((game) => game !== undefined)
      .reduce((a, b) => a + b.totalPlaytime, 0) / 60
  ); // hours
  // console.log('allGamesPlayTimeByHours', allGamesPlayTimeByHours);
  const allGamesPlayTimeInYears = (allGamesPlayTimeByHours / 24 / 365).toFixed(
    2
  );
  const allGamesPlayTimeDays = (allGamesPlayTimeByHours / 24).toFixed(0);
  // console.log('allGamesPlayTimeInYears', allGamesPlayTimeInYears);

  const mostPlayedGames = mostPlayedGamesByPerson
    .filter((game) => game !== undefined)
    .reduce((acc, cur) => {
      // console.log('cur', cur);
      const sameGame = acc.find(
        (game) => game.gameName === cur.mostPlayedGame.name
      );
      if (sameGame) {
        sameGame.playTimeForever =
          sameGame.playTimeForever + cur.mostPlayedGame.playtime_forever;
      } else {
        const game = {
          gameName: cur.mostPlayedGame.name,
          playTimeForever: cur.mostPlayedGame.playtime_forever,
        };
        acc.push(game);
      }
      return acc;
    }, []);

  const mostPlayedGamesConverted = mostPlayedGames.map((game) => ({
    gameName: game.gameName,
    playTimeForever: parseInt((game.playTimeForever / 60 / 24).toFixed(0)),
  }));

  const randomColor = () =>
    `#${Math.floor(Math.random() * 16777215).toString(16)}`;

  const renderTooltip = (tooltip) => (
    <StyledRenderTooltip>
      <p>Peli: {tooltip.payload[0] && tooltip.payload[0].payload.gameName}</p>
      <p>
        Pelattu:{" "}
        {tooltip.payload[0] && tooltip.payload[0].payload.playTimeForever}{" "}
        Päivää
      </p>
    </StyledRenderTooltip>
  );

  return (
    <div className="App">
      <Navigation history={history} />
      <CommunityView>
        <h1>Pelijonnejen suosikkipelit</h1>
        <h2>
          Tämä jengi käyttänyt steam peleihin yhteensä{" "}
          <span className="highlight">{allGamesPlayTimeDays}</span> päivää, eli{" "}
          <span className="highlight">{allGamesPlayTimeInYears}</span> vuotta
        </h2>
        <h2>
          Suosikkipelejä pelattu yhteensä:{" "}
          <span className="highlight">
            {totalFavouriteGamesPlayedTimeInDays}
          </span>{" "}
          päivää, eli{" "}
          <span className="highlight">
            {totalFavouriteGamesPlayedTimeInYears}
          </span>{" "}
          vuotta
        </h2>
        <h2>Suosikkipelit päivinä</h2>
        <ul>
          {mostPlayedGamesConverted.map((game, index) => (
            <li key={index}>
              {game.gameName}:{" "}
              <span className="highlight">{game.playTimeForever} päivää</span>
            </li>
          ))}
        </ul>
        <StyledChartContainer>
          <ResponsiveContainer width="99%" height={300}>
            <PieChart width={800} height={800}>
              <Pie
                data={mostPlayedGamesConverted}
                dataKey="playTimeForever"
                nameKey="gameName"
                cx="50%"
                cy="50%"
                outerRadius={50}
              >
                {mostPlayedGamesConverted.map((entry, index) => (
                  <Cell fill={randomColor()} key={index} />
                ))}
              </Pie>
              <Tooltip content={renderTooltip} />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </StyledChartContainer>
      </CommunityView>
    </div>
  );
};

export default Community;
